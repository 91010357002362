import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Seo, Complet } from "../components"
import { StaticImage } from "gatsby-plugin-image"

const NosRealisations = () => {
  return (
    <Complet>
      <Seo 
      title="Nos réalisations - Agence web Koomet" 
      desc="Découvrez les réalisations de notre agence web : site internet vitrine, blogs, site-ecommerce..."
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Nos réalisations"
      bread2url="/nos-realisations/"
      />
      
      <div class="mx-auto">


      <div className="inset-x-0 bottom-0 h-1/2" />
            <div className="relative shadow-xl sm:overflow-hidden dark:shadow-md">
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-t bg-w-primary mix-blend-multiply" />
              </div>
              <div className="relative text-center px-5 pt-5 pb-10 max-w-5xl mx-auto">
                <h1 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="text-5xl font-extrabold tracking-tight text-white">Nos réalisations</h1>
                <p data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="mt-6 text-lg text-white">
                  Cette page est en construction... En attendant, voici quelques unes de nos réalisations ! 
                </p>

              </div>
            </div>
       


      <div className="container mx-auto flex flex-col lg:px-20">
        <main className="flex-grow flex flex-col justify-center w-full mx-auto px-4 sm:px-6 py-4">
        
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
              <div className="col-span-2 w-full text-center">
                <div className="m-2">
              <StaticImage
                src="../assets/Nouvelair-ventilation.webp"
                quality={100}
                alt="Création de site internet responsive pour Nouvelair Ventilation"
                className="transition-all duration-700 ease-in-out"
              />
              </div>
              </div>
              <div className="w-full text-center">
                <div className="m-2">
              <StaticImage
                src="../assets/Nouvelair-ventilation2.webp"
                quality={100}
                alt="Site internet vitrine avec formulaire de contact pour Nouvelair Ventilation"
                className="transition-all duration-700 ease-in-out h-full w-full"
              /></div>
              <div className="m-2">
              <StaticImage
                src="../assets/Nouvelair-ventilation3.webp"
                quality={100}
                alt="Exemple de site internet vitrine"
                className="transition-all duration-700 ease-in-out h-full w-full"
              />
              </div>
              </div>
            </div>


            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
              
              <div className="w-full text-center">
                <div className="m-2">
              <StaticImage
                src="../assets/HebergeTonCube3.webp"
                quality={100}
                alt="Site internet pour hébergeur de serveur de jeux"
                className="transition-all duration-700 ease-in-out h-full w-full"
              /></div>
              <div className="m-2">
              <StaticImage
                src="../assets/HebergeTonCube2.webp"
                quality={100}
                alt="Template pour hébergeur minecraft"
                className="transition-all duration-700 ease-in-out h-full w-full"
              />
              </div>
            
              </div>
              <div className="col-span-2 w-full text-center">
                <div className="m-2">
              <StaticImage
                src="../assets/HebergeTonCube.webp"
                quality={100}
                alt="Site e-commerce"
                className="transition-all duration-700 ease-in-out"
              />
              </div>
              </div>
            </div>


            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
              <div className="col-span-2 w-full text-center">
                <div className="m-2">
              <StaticImage
                src="../assets/ElementsInnovate1.webp"
                quality={100}
                alt="Création site internet vitrine et blog Elements Innovate"
                className="transition-all duration-700 ease-in-out"
              />
              </div>
              </div>
              <div className="w-full text-center">
                <div className="m-2">
              <StaticImage
                src="../assets/ElementsInnovate2.webp"
                quality={100}
                alt="Site web responsive blog"
                className="transition-all duration-700 ease-in-out h-full w-full"
              /></div>
              <div className="m-2">
              <StaticImage
                src="../assets/ElementsInnovate3.webp"
                quality={100}
                alt="Exemple de réalisation de blog"
                className="transition-all duration-700 ease-in-out h-full w-full"
              />
              </div>
              </div>
            </div>

        </main>
      </div>
      </div>
    </Complet>
  )
}

export default NosRealisations
